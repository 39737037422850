define(['app'], function(app) {

  var stickyMenuHeader = function() {
    var component = {};
    component.app = app;

    var _config = {
      stickyMenuHeaderStickyClass: 'stickyMenuHeader-sticky',
      stickyMenuHeaderSelector: '[data-component="stickyMenuHeader"]',
      stickyMenuHeader_menuToggleSelector: '[data-js-element="stickyMenuHeader_menuToggle"]',
      stickyMenuHeader_menuToggleActiveClass: 'stickyMenuHeader_menuToggle-active',
      stickyMenuHeader_bodyToggleActiveClass: 'stickyMenuHeader_bodyToggle-active',
      mainNavSectionSelector: '[data-js-element="mainNavSection"]',
      mainNavSectionActiveClass: 'mainNavSection-active'
    };

    /*
     * Please see the following discussion regarding the use of clientHeight:
     * https://gitlab.io.thehut.local/elysium/elysium/merge_requests/5858#note_450159
     */
    var _init = function(element) {

      component.element = element;
      component.headerHeight = component.element.clientHeight;
      component.menuToggle = component.element.querySelector(component.config.stickyMenuHeader_menuToggleSelector);
      component.mainNavSection = component.element.querySelector(component.config.mainNavSectionSelector);

      component.attachListeners();

      return component;
    };

    var _attachListeners = function() {
      window.addEventListener('scroll', component.doScroll);
      component.menuToggle.addEventListener('click', component.stickyMenuHeader_menuToggle);
      component.menuToggle.addEventListener('click', component.mainNavSectionToggle);
    };

    var _doScroll = function() {
      var scrollPosition = window.pageYOffset;
      component.showMenu(scrollPosition);
    };

    var _showMenu = function(scrollPosition) {
      if (scrollPosition >= component.headerHeight) {
        app.element.addClass(component.config.stickyMenuHeaderStickyClass, component.element);
        app.element.addClass(component.config.stickyMenuHeader_bodyToggleActiveClass, document.body);
      } else {
        app.element.removeClass(component.config.stickyMenuHeaderStickyClass, component.element);
        app.element.removeClass(component.config.stickyMenuHeader_bodyToggleActiveClass, document.body);
      }
    };

    var _stickyMenuHeader_menuToggle = function() {
      app.element.toggleClass(component.config.stickyMenuHeader_menuToggleActiveClass, component.menuToggle);
    };

    var _mainNavSectionToggle = function() {
      app.element.toggleClass(component.config.mainNavSectionActiveClass, component.mainNavSection);
    };

    component.config = _config;
    component.init = _init;
    component.attachListeners = _attachListeners;
    component.doScroll = _doScroll;

    component.showMenu = _showMenu;
    component.stickyMenuHeader_menuToggle = _stickyMenuHeader_menuToggle;
    component.mainNavSectionToggle = _mainNavSectionToggle;

    return component;
  };

  return stickyMenuHeader;
});
